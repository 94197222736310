<script setup lang="ts">
import { ButtonHTMLAttributes, computed, useSlots } from 'vue';

import IconSpinner from '../Icons/IconSpinner.vue';

type ButtonTextSize = 'sm' | 'md' | 'lg';
export type ButtonTextTheme =
  | 'primary'
  | 'primary-light'
  | 'primary-pink'
  | 'white'
  | 'gray'
  | 'danger';

type ButtonFontWeight = 'normal' | 'bold';

type ButtonBorderRadius = 'rounded' | 'none';

const slots = useSlots();
const {
  disabled,
  fullWidth,
  iconLeft,
  label,
  loadingLabel,
  loading,
  tabindex,
  active,
  type = 'button',
  size = 'md',
  theme = 'primary',
  fontWeight = 'bold',
  borderRadius = 'rounded'
} = defineProps<{
  disabled?: ButtonHTMLAttributes['disabled'];
  fullWidth?: boolean;
  iconLeft?: boolean;
  label?: string;
  loadingLabel?: string;
  loading?: boolean;
  type?: ButtonHTMLAttributes['type'];
  size?: ButtonTextSize;
  tabindex?: ButtonHTMLAttributes['tabindex'];
  theme?: ButtonTextTheme;
  active?: boolean;
  fontWeight?: ButtonFontWeight;
  borderRadius?: ButtonBorderRadius;
}>();

const isDisabled = computed(() => loading || disabled);
const defaultLoading = loading && !loadingLabel;

defineEmits(['click']);

const buttonThemeClasses = new Map<ButtonTextTheme, string>([
  [
    'primary-light',
    'bg-primary-50 text-primary hover:bg-primary-100 hover:text-primary-600 disabled:opacity-50 hover:disabled:bg-primary-50'
  ],
  [
    'primary-pink',
    'bg-pink text-white hover:bg-pink-400 disabled:opacity-50 hover:disabled:bg-primary-50'
  ],
  [
    'primary',
    'bg-primary hover:bg-primary-600 text-white hover:text-white disabled:opacity-50 hover:disabled:bg-primary'
  ],
  [
    'white',
    'hover:bg-gray-100 text-gray-500 hover:text-gray-700 disabled:opacity-50 disabled:hover:bg-white disabled:hover:text-gray-500'
  ],
  [
    'gray',
    'bg-gray-100 hover:bg-gray-200 text-gray-500 hover:text-gray-700 disabled:opacity-50 disabled:hover:bg-gray-100 disabled:hover:text-gray-500'
  ],
  [
    'danger',
    'bg-white hover:bg-gray-100 text-error hover:text-error-600 disabled:opacity-50 disabled:hover:bg-white disabled:hover:text-error'
  ]
]);

const buttonSizeClasses = new Map<ButtonTextSize, string>([
  ['sm', 'hb-btn--sm'],
  ['md', 'hb-btn--md'],
  ['lg', 'hb-btn--lg']
]);

const buttonFontWeightClasses = new Map<ButtonFontWeight, string>([
  ['normal', 'font-normal'],
  ['bold', 'font-bold']
]);

const buttonBorderRadius = new Map<ButtonBorderRadius, string>([
  ['none', ''],
  ['rounded', 'rounded-md']
]);

const iconLeftClass = computed(() => {
  return iconLeft ? 'flex-row-reverse' : '';
});

const fullWidthClasses = computed(() => (fullWidth ? 'w-full' : null));

const activeClasses = new Map<ButtonTextTheme, string>([
  ['primary-light', 'bg-primary-100 text-primary-600'],
  ['primary', 'bg-primary-600'],
  ['white', '!bg-gray-100 text-gray-700'],
  ['gray', '!bg-gray-200 text-gray-700'],
  ['danger', '!bg-gray-100 text-error-700']
]);

const currentActiveClasses = computed(
  () => (active && activeClasses.get(theme)) || ''
);
const classes = computed(() => {
  return [
    buttonThemeClasses.get(theme),
    buttonSizeClasses.get(size),
    fullWidthClasses.value,
    iconLeftClass.value,
    currentActiveClasses.value,
    buttonFontWeightClasses.get(fontWeight),
    buttonBorderRadius.get(borderRadius)
  ];
});
</script>

<template>
  <button
    class="hb-button group relative"
    :class="classes"
    :arial-label="label"
    :title="label"
    :tabindex="tabindex"
    :disabled="isDisabled"
    :type="type"
    @click="$emit('click')"
  >
    <span
      v-if="defaultLoading"
      class="absolute left-0 top-0 flex size-full items-center justify-center"
      ><IconSpinner class="text-xl"
    /></span>
    <span :class="{ 'opacity-0': defaultLoading }">
      <slot name="default">
        <template v-if="loading && loadingLabel">{{ loadingLabel }}</template>
        <template v-else-if="label">{{ label }}</template>
      </slot>
    </span>
    <span
      v-if="slots.icon"
      :class="{ 'opacity-0': defaultLoading }"
      class="group-[.hb-btn--md]:text-xl group-[.hb-btn--sm]:text-base"
    >
      <slot name="icon" />
    </span>
  </button>
</template>
